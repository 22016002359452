import React from 'react';
import './ESIMExplanation.css'; // CSS for styling the component

const ESIMExplanation = ({ handleNext }) => {
  return (
    <div className="esim-explanation">
      <h2>What is eSIM?</h2>
      <div className="esim-content">
        <div className="esim-text">
          <ul>
            <li>eSIM is a convenient alternative to physical SIM cards.</li>
            <li>It saves time and effort, especially when traveling.</li>
            <li>We offer low-cost eSIM plans with Unlimited Data.</li>
            <li>Instant activation—no waiting, no shipping, just scan&go!</li> {/* New line added */}
          </ul>
        </div>
        <div className="esim-image">
          <img src="./explain.webp" alt="eSIM Illustration" />
        </div>
      </div>
      <br />
      {/* Call handleNext when the button is clicked */}
      <button className="cta-button" onClick={handleNext}>Get Your eSIM Now!</button>
    </div>
  );
};

export default ESIMExplanation;
