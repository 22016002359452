import React, { useState, useEffect } from 'react';

const CookieConsent = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false); // Initially not visible until we check localStorage

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    
    if (cookieConsent === 'true') {
      onAccept(); // Initialize GTM if already accepted
    } else if (cookieConsent === 'false') {
      // Optional action if cookie consent was explicitly declined
      // You can leave this empty or add specific code for this case
    } else {
      setIsVisible(true); // Show the consent modal if no decision is stored
    }
    
  }, [onAccept]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
    onAccept(); // Initialize GTM after accepting
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // If not visible, render nothing
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '30px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow to the box
          maxWidth: '500px',
          textAlign: 'center',
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>We use cookies to improve your experience</h1>
        <p style={{ marginBottom: '30px' }}>
          By clicking "Accept", you agree to the use of cookies for analytics and personalized content. You can also
          decline if you prefer not to share your data. For more information, please read our{' '}
          <a href="/privacypolicy.txt" style={{ color: '#006EFF', textDecoration: 'underline' }}>
            Cookie Policy
          </a>
          .
        </p>
        <div>
          <button
            onClick={handleDecline}
            style={{
              padding: '10px 20px',
              marginRight: '10px',
              fontSize: '16px',
              backgroundColor: 'grey',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add shadow to buttons
            }}
          >
            Decline
          </button>

          <button
            onClick={handleAccept}
            style={{
              marginLeft: '10px',
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add shadow to buttons
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
