import React from 'react';

const Terms = () => {

  const handleRedirect = () => {
    // Redirects the user to the privacy policy URL
    window.location.href = "/termsandconditions.txt";  // Replace with your actual URL
  };

  return (
    <>
<button onClick={handleRedirect} className="open-modal-btn" style={{ background: 'none', border: 'none', padding: 0, margin: 0, cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}>
    Terms & Conditions
  </button>
    </>
  );
};

export default Terms;
