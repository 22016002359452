import React from 'react';
import './help.css'; // CSS for styling the component

const Help = () => {
  return (
    <div className="esim-explanation">
      <h2>Our Support</h2>
      <div className="esim-content">
      <div className="esim-image">
      
          <img src="./help.webp" alt="our help" />
        </div>
        <br></br>

        <div className="esim-text">
          <ul>
            <li>24/7 WhatsApp assistant</li>
            <li>Changed your mind? We’ll refund you</li>
            <li>Not working? We’ll refund you</li>
            <li>Not happy? We’ll refund you</li>
          </ul>
        </div>
      
      </div>
    </div>
  );
};

export default Help;
