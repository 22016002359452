import React from 'react';
import Terms from './terms';
import Terms_he from './terms_he';
import Privacy from './PrivacyPolicyModal';
import Privacy_he from './PrivacyPolicyModal_he';
import { useTranslation } from 'react-i18next';
import './footer.css'; // Create this CSS file for styles

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        {i18n.language === 'he' ? <Terms_he /> : <Terms />}
        {i18n.language === 'he' ? <Privacy_he /> : <Privacy />}
        <img
          src="shield.png" // Your image path
          alt="SSLShield"
          className="ssl-shield" // Move styling to CSS
        />
      </div>
    </footer>
  );
};

export default Footer;
