import React, { useState, useEffect } from 'react';
import useCatalog from './components/catalog';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ESIMInstructions_he from './components/ESIMInstructions_he';
import ESIMInstructions from './components/ESIMInstructions';
import Payment from './components/payment';
import CookieConsent from './components/CookieConsent'; // Import the CookieConsent component
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './i18n';
import { useTranslation } from 'react-i18next';
import Review from './components/reviews';
import Footer from './components/footer'; // Import Footer
import Step2 from './components/Step2';
import ESIMExplanation from './components/ESIMExplanation';
import Operators from './components/Operators';
import Help from './components/Help';
import ReactPixel from 'react-facebook-pixel';
import Flag from 'react-world-flags';

import ComparisonTable from './components/ComparisonTable';
// Helper function to check if language is RTL
const isRTL = (language) => {
  return ['he', 'ar', 'fa', 'ur'].includes(language);
};

const options = {
  autoConfig: true,   // set pixel's autoConfig
  debug: false,       // enable logs
};
const App = () => {

  const [videoSrc, setVideoSrc] = useState(null); // Initialize with null
  const [isTurkeyPage, setIsTurkeyPage] = useState(false); // State to check if it's Turkey page


  useEffect(() => {
    const path = window.location.pathname.toLowerCase(); // Convert to lowercase for consistency

    if (path.includes('/turkey')) {
      setVideoSrc('/Turkey.mp4');
      setIsTurkeyPage(true); // Set flag to true for Turkey-specific text
    } else {
      setVideoSrc('/videomainscreen.mp4');
      setIsTurkeyPage(false); // Set flag to false for other pages
    }
  }, []);


  let once = false; // Use let instead of const for the flag
  
  useEffect(() => {
    if (once === true) {
      return; // Exit if already executed
    }
    
    once = true; // Set the flag to true after first execution

    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'true') {

      ReactPixel.init('338540379280450', options); // Replace 'YOUR_PIXEL_ID' with your actual Facebook Pixel ID
      ReactPixel.pageView(); // For tracking page views
      ContentDetailPage('Landing page','1');
      if (typeof window.installGTag === 'function') {
        window.installGTag()

          .then(() => {
            console.log('Google Tag Manager loaded successfully.');
          })
          .catch(err => {
            console.error('Failed to load Google Tag Manager:', err);
          });

       
      } else {
        console.error('installGTag function is not available.');
      }
    }
  }, []);
 


  const [isFloating, setIsFloating] = useState(false);

  const [selectedDays, setSelectedDays] = useState(1); // Track selected number of days
  const [totalPrice, setTotalPrice] = useState(0);
  

  const { t, i18n } = useTranslation();
  const rtl = isRTL(i18n.language);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };
  const [isMobile, setIsMobile] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const countries = useCatalog();

  const ContentDetailPage = (pageName,id) => {
    const cookieConsent = localStorage.getItem('cookieConsent');

    if (cookieConsent === 'true') {
      // Track ViewContent when the component is loaded
      ReactPixel.track('ViewContent', {
        content_name: pageName,  // Add relevant content details
        content_ids: id,        // Add your product or content ID
        content_type: 'page',       // Specify the type of content (product, article, etc.)
      }); }
  }
  const handleAddToCart = (plan) => {
    const cookieConsent = localStorage.getItem('cookieConsent');

    if (cookieConsent === 'true') {
    // Track AddToCart event
    ReactPixel.track('AddToCart', {
      content_name: plan,   // Name of the product
      content_ids: ['5'],         // Product ID
      content_type: 'product',        // Type of content (product, service, etc.)
    });
    console.log('Add to Cart event tracked.');
  }
  };




  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname;
      if (path.startsWith('/he')) {
        i18n.changeLanguage('he');
        setSelectedLanguage('he');
      } else {
        i18n.changeLanguage('en');
        setSelectedLanguage('en');
      }
    }
  }, [i18n]);
  
  const handleWhatsapp = () => {
    // Construct the WhatsApp URL with the phone number in international format (no '+' or special characters)
    const whatsappUrl = `https://wa.me/${+972559820737}`;
    window.open(whatsappUrl, '_blank'); // Open WhatsApp in a new tab
  };
  // Side effect to set the page direction based on the current language
  useEffect(() => {
    document.documentElement.dir = rtl ? 'rtl' : 'ltr';
  }, [rtl]);


  // Function to handle country selection and calculate the total price
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setTotalPrice(country.pricePerDay * selectedDays); // Calculate the total price when a country is selected
  };

  // Function to handle day selection and update total price accordingly
  const handleDaysChange = (e) => {
    const days = Number(e.target.value);
    setSelectedDays(days);
    if (selectedCountry) {
      setTotalPrice(days * selectedCountry.pricePerDay); // Calculate total price based on days
    }
  };
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
   
  };
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust this value for your definition of "mobile"
  };  useEffect(() => {
    const handlePopState = (event) => {
      // Handle going back to the previous step when the user presses the back button
      if (currentStep > 1) {
        setCurrentStep((prevStep) => prevStep - 1);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [currentStep]);


  // Function to handle going to the next step
  const handleNext = () => {
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // optional, for a smooth scrolling effect
    });
    if (currentStep === 1)
      {
        ContentDetailPage('Select Bundle','2');
      }
      if (currentStep === 2)
        {
          ContentDetailPage('Checkout','3');
        }
      setCurrentStep((prev) => prev + 1);

      
  
      // Push the new step to the browser history
      window.history.pushState({ step: currentStep + 1 }, '');
      if (currentStep === 2)
      {
        if (selectedCountry && selectedDays && totalPrice) {
          const plan = {
            name: selectedCountry.name,
            country: selectedCountry.country,
            countryHeb: selectedCountry.countryHeb,
            bundle: selectedCountry.bundle,
            pricePerDay: selectedCountry.pricePerDay,
            days: selectedDays,
            price: totalPrice
          };
          setSelectedPlan(plan); // Set the flat selectedPlan

          handleAddToCart(plan);
      };
      if (isMobileDevice()) {
        window.scrollTo(0, 0);
      }
    } else {
      console.error('Please select a country and number of days');
    }
  };
  

  // Function to handle going to the previous step
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);

      // Push the previous step to the browser history
      window.history.pushState({ step: currentStep - 1 }, '');
      
      if (isMobileDevice()) {
        window.scrollTo(0, 0);
      }
    }
  };

    // Function to handle scroll and toggle floating class for "ORDER NOW" button
    const handleScroll = () => {
      const ctaButton = document.getElementById('orderNowButton');
      const triggerPoint = 0; // Change this value depending on when you want the button to float
  
      if (window.scrollY > triggerPoint) {
        setIsFloating(true);
      } else {
        setIsFloating(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
      </Helmet>

      <div className="App" dir={rtl ? 'rtl' : 'ltr'}>
      <CookieConsent onAccept={ window.installGTag} />

        <div>
   
</div>
        <div className="language-button" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
       
        <div
      style={{
        display: 'flex',
        flexDirection: 'row', // Changed from 'column' to 'row'
        alignItems: 'center', // Vertically center the items
        // Optional: Add padding or other styles as needed
      }}
    >
  {/*   <Dropdown>
        <Dropdown.Toggle
          id="language-dropdown"
          variant="link"
          className="earth-button"
          style={{
            backgroundColor: '#006EFF', // Button background color
            color: 'white', // Button text color
            padding: '8px 12px', // Reduced padding for smaller width
            borderRadius: '30px', // Rounded corners
            border: 'none', // Remove default border
            display: 'flex', // Use flexbox for layout
            alignItems: 'center', // Center items vertically
            justifyContent: 'center', // Center items horizontally
            gap: '6px', // Slightly reduced spacing between icon and text
            cursor: 'pointer',
            minWidth: '100px', // Set a minimum width to ensure content fits
            // Optional: Adjust the width based on content
            // width: 'auto',
          }}
        >
          <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '18px' }} />
          <span style={{ textAlign: 'center' }}>
            {selectedLanguage === 'en' ? 'En' : 'עב'}
          </span> 
        </Dropdown.Toggle>

        <Dropdown.Menu
          align="end"
          style={{
            width: '100px', // Adjusted width
            padding: '0', // Remove default padding
          }}
        >
          <Dropdown.Item
            onClick={() => handleLanguageChange('en')}
            style={{
              padding: '6px 10px', // Reduced padding
              textAlign: 'center', // Center the text within the dropdown items
            }}
          >
            English
          </Dropdown.Item>
        
        </Dropdown.Menu>
      </Dropdown>
      */}

     
    </div>
</div>
<img 
            className="logo"
      src="VIOFLY.webp" // Your image path
    />
{currentStep === 1 && (

            <>

 

  
            <h1 className="bundle-text">Unlimited eSIM Data Abroad</h1>
          {/* Conditionally render this text only if the page is /turkey */}
          {isTurkeyPage && (
            <h1 className="target-text">
              Your Best eSIM for Turkey <Flag code="TR" className="flag-icon" />
            </h1>
          )}

         <h1 className="promo-text">Cheapest Price on the Market</h1>

            <button className="helpfloat" onClick={handleWhatsapp}>
            Whatsapp 24/7 support</button>


            <button
        id="orderNowButton"
        className={`cta`}
        onClick={handleNext} // Handle button click as per your logic
      >
get your esim now           </button>

               <button
        id="orderNowButton"
        className={`ctaheader`}
        onClick={handleNext} // Handle button click as per your logic
      >
order now
               </button>



         
               <div>
               <div className="video-container">
        {videoSrc && ( // Only render the video element when videoSrc is set
          <video autoPlay loop muted playsInline className="main-video">
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>

<br/>
<ComparisonTable/>

<br/>
<ESIMExplanation handleNext={handleNext} />
 
<br/>
<Review/>

<br/>

<Help/>


<br/>
          
          {  (
            <>

              {i18n.language === 'he' ? <ESIMInstructions_he /> : <ESIMInstructions />}
            </>
          )}
  
            
{/*<DeviceListModal /> */}
<br></br>
 <Operators/>

            </>
            
          
          )}

        <main>
      
{currentStep === 2 && (

  // Update the URL to /SelectBundle
  <Step2
  currentStep={currentStep}
  selectedCountry={selectedCountry} // Pass selectedCountry here
  setSelectedCountry={setSelectedCountry}
  handleCountrySelect={handleCountrySelect}
  selectedDays={selectedDays} // Pass selectedDays here
  handleDaysChange={handleDaysChange}
  totalPrice={totalPrice} // Pass totalPrice here
  countries={countries}
  handleNext={handleNext}
  />

)}


  
  {currentStep === 3 && (
  <>
   
  
              <Payment
        selectedBundle={selectedPlan} // Pass selectedPlan as selectedBundle 
                key={selectedPlan.price}
              />
              <div style={{ marginTop: '20px' }}>
                <button
                  className="buy-button"
                  onClick={handleBack}
                  style={{background: 'grey',width: '110px' }}
                >
                  {t('go_back')}
                </button>
              </div>
  </>
)}
  
     
        
        </main>
        <Footer />
      </div>

    </HelmetProvider>
  );
  
};

export default App;
