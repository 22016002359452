import React from 'react';
import './ComparisonTable.css'; // Assuming you have external CSS for additional styling

const ComparisonTable = () => {
  const data = [
    { country: 'Italy 7 Days', VIOFLY: 18.9, holaFly: 28.9, jetSim: 24.2 },
    { country: 'Greece 1 Day', VIOFLY: 2.75, holaFly: 5.9, jetSim: 7.7 },
    { country: 'France 7 Days', VIOFLY: 18.9, holaFly: 28.9, jetSim: 24.2 },
    { country: 'Spain 1 Day', VIOFLY: 2.75, holaFly: 5.9, jetSim: 7.7 },
    { country: 'Thailand 1 Day', VIOFLY: 4.02, holaFly: 5.9, jetSim: 7.7 },
    { country: 'Finland 1 Day', VIOFLY: 2.75, holaFly: 5.9, jetSim: 7.7 }
  ];

  const getCheapest = (row) => {
    const prices = [row.VIOFLY, row.holaFly, row.jetSim];
    return Math.min(...prices);
  };

  const calculatePercentage = (viofly, holafly) => {
    const difference = ((holafly - viofly) / holafly) * 100;
    return `-${difference.toFixed(0)}%`;
  };

  return (
    <div className="container">
      <div className="comparison-table">
        <h1>eSIM Comparison - Unlimited bundles</h1>
        <table>
          <thead>
            <tr>
              <th>Country/Duration</th>
              <th className="VIOFLY-header">VIOFLY</th>
              <th>HolaFly</th>
              <th>JetSim</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const cheapestPrice = getCheapest(row);
              const percentageDifference = calculatePercentage(row.VIOFLY, row.holaFly);

              return (
                <tr key={index}>
                  <td>{row.country}</td>
                  <td className={`VIOFLY-selected ${row.VIOFLY === cheapestPrice ? 'cheapest' : ''}`}>
                    ${row.VIOFLY} <span className="percentage-difference">({percentageDifference})</span>
                  </td>
                  <td className={row.holaFly === cheapestPrice ? 'cheapest' : ''}>
                    ${row.holaFly}
                  </td>
                  <td className={row.jetSim === cheapestPrice ? 'cheapest' : ''}>
                    ${row.jetSim}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonTable;
